
import { Options, Vue } from 'vue-class-component';
import PaymentBoxRouteAirport from '../PaymentBoxRouteAirport.vue';
import { addMinutesToTime, getHourMinutesFromString } from '@/utils';
import { useAirportBookingStore } from '@/store/modules/airportBooking';


@Options({
    name: "PaymentRouteAirport",
    props: {
        isReturn: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    components: {
        PaymentBoxRouteAirport
    }
})
export default class PaymentRouteAirport extends Vue {
    public airportBookingStore = useAirportBookingStore();
    public isReturn !: boolean;

    get origin(){
        if(this.airportBookingStore.typeTripSelected == 'fromAiport'){
            return 'SCL - Arturo Merino Benitez'
        }
        if(!this.airportBookingStore.form.destinationText){
            return ''
        }
        return this.airportBookingStore.form.destinationText.replace(', Chile','')
    }

    get destination(){
        if(this.airportBookingStore.typeTripSelected == 'toAiport'){
            return 'SCL - Arturo Merino Benitez'
        }
        if(!this.airportBookingStore.form.destinationText){
            return ''
        }
        return this.airportBookingStore.form.destinationText.replace(', Chile','')
    }

    get hourInit(){
        return this.airportBookingStore.form.hour;
    }

    get hourFinish(){
        if(!this.airportBookingStore.vehicleAiportSelected || !this.hourInit){
            return 'ERROR'
        }
        const duration = this.airportBookingStore.vehicleAiportSelected.duration;

        return addMinutesToTime(this.hourInit,duration)
    }
}
