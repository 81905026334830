

import { useAuthStore } from '@/store/modules/auth';
import { Options, Vue } from 'vue-class-component';

import webPayImagen from "@/assets/images/logo-webpay.png";
import onePayImagen from "@/assets/images/logo-onepay.png";
import visaImagen from "@/assets/images/visa_logo.png";
import { getErrorCatch, requiredField } from '@/utils';
import { UserApi } from '@/api/users';
import TextLoaderButton from '@/components/common/LoaderButtons.vue';
import { FormVuetify } from '@/types/common/vuetify';
import { useAirportBookingStore } from '@/store/modules/airportBooking';

@Options({
    name: "PaymentMethodsAirport",
    components: {
        TextLoaderButton
    },
    props: {
        loadingTrip: {
            type: Boolean,
            default: false,
            required: false
        }
    }

})
export default class PaymentMethodsAirport extends Vue {
    public storeAuth = useAuthStore();
    public airportBookingStore = useAirportBookingStore();
    private userApi = new UserApi();
    public select = "webpay";
    public webPayImagen = webPayImagen;
    public onePayImagen = onePayImagen;
    public visaImagen = visaImagen;
    public loadingAddCard = false;
    public requiredField = requiredField;
    public policy = false;
    public loadingTrip!: boolean;

    async addCardFlow() {
        this.loadingAddCard = true;
        try {
            const { token, url } = await this.userApi.addCard("booking?addCard=");
            window.location.href = `${url}?token=${token}`;
        } catch (error) {
            getErrorCatch(error);
        }
        this.loadingAddCard = false;
    }


    back() {
        this.airportBookingStore.setStep("data-user");
    }

    async nextTo() {
        const form = this.$refs["formPayment"] as FormVuetify;
        if (!form) return;
        const valid = await form.validate();
        if (valid.valid) {
            this.$emit('nextTo',this.select)
        }
    }
}
