
import { formatDateCompleted } from '@/filters/formatDate';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { GetPlaceIdRes, VehicleListMapping } from '@/types/response/locations';
import { Options, Vue } from 'vue-class-component';
import imageCar from '@/assets/car.png'
import { customScrollTo, formatCurrency, getErrorCatch, sleep } from '@/utils';
import ModalCommon from '../common/ModalCommon.vue';

@Options({
    name: 'DetailSector',
    components: {
        ModalCommon
    }
})
export default class DetailSector extends Vue {
    public imageCar = imageCar;
    public airportStore = useAirportBookingStore();
    public formatDateCompleted = formatDateCompleted;
    public formatCurrency = formatCurrency;
    public isVisibleAlert = false
    public hourSelected: string | null = null;
    public timeOut = 0;
    public termsTemp = [
        'Se permite cancelar con al menos 24 horas de anticipación al inicio del servicio con un reembolso del 100%.',
        'Esta minivan cuenta con capacidad máxima para {seatings} personas.',
        'Solo se permite la cantidad de maletas indicadas en la descripción del tipo de vehículo.',
        'Contamos con sillas para niños sin cargo adicional. Solicitar cuando sea confirmada su reserva.',
        'Para traslado de mascota consultar ANTES de reservar. Mascotas medianas y grandes deben ir en jaula, esta cuenta como maleta. Si es mascota pequeña debe ir en su debido bolso de traslado.'
    ]
    public terms: string[] = []
    public hours: string[] = [];
    public orderByKey: 'price' | 'duration' | 'seatings' | 'bags' = 'price';
    public orderByAscDesc: 'asc' | 'desc' = 'asc';

    public vehiclesAvailable: GetPlaceIdRes[] = [];
    public vehicleSelected: VehicleListMapping | null = null

    get vehicleList(): VehicleListMapping[] {
        const place = this.place;
        if (!place || place.length == 0) {
            return []
        }
        const vehicles: VehicleListMapping[] = []
        for (let index = 0; index < this.place.length; index++) {
            const element = this.place[index];
            for (let index = 0; index < element.vehicles.length; index++) {
                const elementVehicle = element.vehicles[index];
                vehicles.push({
                    _id: element._id,
                    bags: elementVehicle._id.bags,
                    seatings: elementVehicle._id.seatings,
                    duration: element.duration,
                    nameType: element.typeVehicle.name,
                    price: element.price
                })
            }
        }
        const vehicleOrder = this.sortByKey(vehicles)
        return vehicleOrder
    }

    get step() {
        return this.airportStore.step
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get emptyResult() {
        return this.airportStore.emptyResult
    }

    get isMobile() {
        const rect = document.body.getBoundingClientRect();
        return rect.width - 1 < 572;
    }

    async setVehicle(data: VehicleListMapping) {
        this.isVisibleAlert = true;
        this.terms = this.termsTemp.map((term) => {
            const finalTerm = term.replaceAll('{bags}', data.bags.toString()).replaceAll('{seatings}', data.seatings.toString())
            return finalTerm
        })
        this.vehicleSelected = data;
    }

    async setVehicleFinal() {
        if (this.vehicleSelected) {
            try {
                this.airportStore.setVehicleAiport(this.vehicleSelected);
                if (this.isMobile) {
                    this.$nextTick(async () => {
                        await sleep(300, this.timeOut);
                        customScrollTo("dataUserAddressInfo", 0);
                    })
                }
            } catch (error) {
                getErrorCatch(error)
            }
        }
    }

    closeModalAlert() {
        this.isVisibleAlert = false;
        this.vehicleSelected = null;
    }

    sortByKey(listVehicle: VehicleListMapping[]) {
        return listVehicle.sort((a, b) => {
            if (this.orderByAscDesc === 'asc') {
                return a[this.orderByKey] - b[this.orderByKey];
            } else {
                return b[this.orderByKey] - a[this.orderByKey];
            }
        });
    }
}
