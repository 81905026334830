
import { Vue, Options } from 'vue-class-component';
import Calendar, { ISchedule } from 'tui-calendar'; // Importa la instancia base de tui-calendar
import 'tui-calendar/dist/tui-calendar.css'; // Estilo necesario
import { ScheduleApi } from '@/api/schedule'
import { getErrorCatch } from '@/utils';
import { DateTime } from 'luxon';


@Options({
  name: 'DashboardAirport',
  components: {
    Calendar
  }
})
export default class DashboardAirport extends Vue {
  private schedule = new ScheduleApi()
  public viewCalendar: 'day' | 'week' | 'month' = "day"
  public calendar: Calendar | null = null;
  public schedules: ISchedule[] = []

  mounted() {
    this.$nextTick(async () => {
      const calendarRef = this.$refs['calendarContainer'] as HTMLElement;
      if (!calendarRef) {
        console.log("no existe calendario")
        return;
      }

      this.calendar = new Calendar(calendarRef, {
        defaultView: this.viewCalendar,
        taskView: false,
        scheduleView: ['time'],
        useDetailPopup: false,
        isReadOnly: true,
        week: {
          daynames: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          narrowWeekend: false,
          startDayOfWeek: 1
        },
        month: {
          daynames: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
          startDayOfWeek: 1
        },
        template: {
          popupDelete: function () {
            return `Eliminar`;
          },
          popupEdit: function () {
            return 'Empezar'
          },

        }
      });
      this.getMySchedule();
    })
  }

  setViewer(typeView: 'day' | 'week' | 'month' = 'day') {
    this.viewCalendar = typeView;
    this.calendar?.changeView(typeView)
  }
  prev() {
    this.calendar?.prev();
  }

  next() {
    this.calendar?.next();
  }

  async getMySchedule() {
    try {
      const schedules = await this.schedule.get("");
      const mapSchedules: ISchedule[] = schedules.map(({_id,startTime, endTime,destination, origin}) => {
        const splitDestination = destination.split(",");
        const destinationApi = splitDestination.length > 1 ? `${splitDestination[0]},${splitDestination[1]}` : splitDestination[0] 
        const splitOrigin = origin.split(",");
        const originApi = splitOrigin.length > 0 ? `${splitOrigin[0]},${splitOrigin[1]}` : splitOrigin[0] 
        return {
          id: _id,
          calendarId: '1',
          title: `Origen: ${originApi} (${DateTime.fromISO(startTime).toFormat("HH:mm")} hrs) - Destino: ${destinationApi}`,
          category: 'time',
          start: startTime,
          end: endTime,
          bgColor: 'rgb(25,88,127)',
          color: '#fff',
          borderColor: 'rgb(0,204,255)',
          customStyle: 'font-size: 13px;'
        }
      })
      const finalSchedule : ISchedule[] = []
      for (let index = 0; index < mapSchedules.length; index++) {
        const mapSchedule = mapSchedules[index];
        if(!mapSchedule.start || !mapSchedule.end){
          continue
        }
        const dateAfter = DateTime.fromISO(mapSchedule.start.toLocaleString()).minus({hour: 1})
        const dateBefore = DateTime.fromISO(mapSchedule.end.toLocaleString()).plus({hour: 1})
        finalSchedule.push({
          id: `error${index}_1`,
          calendarId: '1',
          start: dateAfter.toString(),
          end: mapSchedule.start,
          title: `Rango 1hr (${dateAfter.get('hour')}:${dateAfter.get('minute')} - ${dateAfter.plus({hour: 1}).get('hour')}:${dateAfter.plus({hour: 1}).get('minute')})`,
          bgColor: '#ff00003b',
          category: "time",
          borderColor: '#890000'
        })
        finalSchedule.push(mapSchedule)
        finalSchedule.push({
          id: `error${index}_2`,
          calendarId: '1',
          start: mapSchedule.end,
          end: dateBefore.toString(),
          title: `Rango 1hr (${dateBefore.minus({hour: 1}).get('hour')}:${dateBefore.minus({hour: 1}).get('minute')} - ${dateBefore.get('hour')}:${dateBefore.get('minute')})`,
          bgColor: '#ff00003b',
          category: "time",
          borderColor: '#890000'
        })
      }
      console.log(finalSchedule)
      this.calendar?.createSchedules(finalSchedule);
    } catch (error) {
      getErrorCatch(error)
    }
  }
}

