
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { CreateTripAddressAirport } from '@/types/request/trip';
import { Options, Vue } from 'vue-class-component';
import imageCar from '@/assets/car.png'
import { TripAirportApi } from '@/api/trip/aiport'
import { FormVuetify } from '@/types/common/vuetify';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import ModalCommon from '../common/ModalCommon.vue';
import { customScrollTo, getErrorCatch, sleep } from '@/utils';

@Options({
    name: 'DetailAdditionalAddress',
    components: {
        HeaderCommonBooking,
        ModalCommon
    }
})
export default class DetailAdditionalAddress extends Vue {
    public imageCar = imageCar;
    public apiAirport = new TripAirportApi()
    public airportStore = useAirportBookingStore();
    public timeOut = 0;
    public addressInfo: CreateTripAddressAirport = {
        additionalInfo : "",
        addressText : "",
        commune: "",
        houseNumber: ""
    }

    get step() {
        return this.airportStore.step
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get additionalAddress() {
        return this.airportStore.addressInfo
    }

    get vehicleAiportSelected() {
        return this.airportStore.vehicleAiportSelected
    }

    get hourSelected() {
        return this.airportStore.hourSelected
    }

    get getValueAddress(){
        if(!this.additionalAddress){
            return '';
        }
        const addressText = this.additionalAddress.addressText
        if(addressText){
            const listAddress = addressText.split(",");
            if(listAddress.length > 1){
                return listAddress[0]
            }
            return addressText
        }
        return ''
    }
    

  get isMobile() {
    const rect = document.body.getBoundingClientRect();
    return rect.width - 1 < 572;
  }
  
    get getCommuneAddress(){
        if(!this.additionalAddress){
            return '';
        }
        const addressText = this.additionalAddress.addressText
        if(addressText){
            const listAddress = addressText.split(",");
            if(listAddress.length > 1){
                return listAddress[1]
            }
            return ''
        }
        return ''
    }


    toBack() {
        this.airportStore.backSelectedVehicle();
    }

    mounted(): void {
        if(this.additionalAddress){
            this.addressInfo =  this.additionalAddress
        }
    }

    async toPassenger() {
        const form = this.$refs['formDataUser'] as FormVuetify
        if (!form) {
            return;
        }
        const valid = await form.validate()
        if (valid.valid) {
            try {
                this.airportStore.setDataAddress({
                    additionalInfo: this.addressInfo.additionalInfo,
                    houseNumber : this.addressInfo.houseNumber
                });
                if(this.addressInfo.commune !== ''){
                    this.airportStore.setDataAddress({
                        commune: this.addressInfo.commune
                    });
                }
                this.airportStore.setStep('data-user');
                if (this.isMobile) {
                    this.$nextTick(async () => {
                        await sleep(300, this.timeOut);
                        customScrollTo("dataUserAddressInfo", 0);
                    })
                }
            } catch (error) {
                getErrorCatch(error)   
            }
        }
    }
}
