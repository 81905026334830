import { LocationsApi } from "@/api/locations";
import { CreateTripAddressAirport, CreateTripOwnerAirport } from "@/types/request/trip";
import { GetHourPlaceVehicle, GetPlaceIdRes, VehicleListMapping } from "@/types/response/locations";
import { defineStore } from "pinia";

export interface SearchFormAirport{
    origin ?: string;
    destination?: string;
    date: Date ;
    dateFinal?:Date;
    hour?:string;
    typeTrip : 'justReturn' | 'round'
    destinationText?:string
    bag?:string | number;
    seatings?:string | number
}

export interface SearchSeatingsAirport{
    adults: number;
    children: number;
    babys:number
}

export interface AirportBookingStore{
    form : SearchFormAirport,
    placeIdSelected: string,
    placeInfo: GetPlaceIdRes[]
    step: 'search' | 'selected-sector' | 'data-address' | 'data-user' | 'payment',
    emptyResult : boolean
    vehicleAiportSelected: VehicleListMapping | null
    hourSelected : string;
    typeTripSelected : 'fromAiport' | 'toAiport' | 'round';
    formDataUser: CreateTripOwnerAirport 
    seatingsInfo: SearchSeatingsAirport
    addressInfo: CreateTripAddressAirport
}

export const useAirportBookingStore = defineStore("airportBooking", {
    state: (): AirportBookingStore => ({
        form : {
            date : new Date(),
            destination : '',
            origin: '',
            typeTrip : 'justReturn',
            bag : '',
            seatings : ''
        },
        seatingsInfo : {
            adults: 0,
            babys: 0,
            children: 0
        },
        placeIdSelected : '',
        placeInfo : [],
        step : 'search',
        emptyResult  : false,
        vehicleAiportSelected : null,
        hourSelected : '',
        typeTripSelected: 'toAiport',
        formDataUser : {
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            rut: ''
        },
        addressInfo: {
            additionalInfo: "",
            addressText: "",
            commune: "",
            houseNumber: ""
        }
    }),
    actions : {
        setDataAddress({additionalInfo,addressText,commune,houseNumber } : CreateTripAddressAirport){
            this.addressInfo = {
                additionalInfo: additionalInfo || this.addressInfo.additionalInfo,
                addressText: addressText || this.addressInfo.addressText,
                commune: commune || this.addressInfo.commune,
                houseNumber: houseNumber || this.addressInfo.houseNumber
            }
        },
        resetAll(){
            this.step == 'search';
            this.formDataUser = {
                email: '',
                firstName: '',
                lastName: '',
                phone: '',
                rut: ''
            },
            this.form = {
                date : new Date(),
                destination : '',
                origin: '',
                typeTrip : 'justReturn',
                bag : '',
                seatings : ''
            }
            this.vehicleAiportSelected = null;
        },
        setTypeTripSelected(typeTripSelected : 'fromAiport' | 'toAiport' | 'round'){
            this.typeTripSelected = typeTripSelected;
        },
        setVehicleAiport(data: VehicleListMapping){
            this.vehicleAiportSelected = data;
            this.step = 'data-address'
        },
        setDataUser(data : CreateTripOwnerAirport){
            this.formDataUser = data;
        },
        setStep(step: 'search' | 'selected-sector' | 'payment' | 'data-user' ){
           this.step = step 
        },
        backSelectedVehicle(){
            this.step = 'data-address';
            this.hourSelected = '';
            this.vehicleAiportSelected = null;
        },
        backAddresInfo(){
            this.step = 'search'
        },
        async getZone(){
            this.emptyResult = false;
            if(!this.form.destination){
                throw new Error("Destination not found")
            }
            const locationApi = new LocationsApi()
            const realSeating = (this.form.seatings as number) - this.seatingsInfo.babys
            this.placeInfo = await locationApi.getZone(this.form,this.typeTripSelected,realSeating)
            if(this.placeInfo == null){
                this.emptyResult = true;
            }
            this.placeIdSelected = this.form.destination
            this.step = 'selected-sector'
        },
        setForm(data : SearchFormAirport){
            this.form = data;
        },
        setSeatingsBabys(data : SearchSeatingsAirport){
            this.seatingsInfo = data
        }
    }
})