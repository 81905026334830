import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentBoxRouteAirport = _resolveComponent("PaymentBoxRouteAirport")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_col, { cols: "6" }, {
      default: _withCtx(() => [
        _createVNode(_component_PaymentBoxRouteAirport, {
          title: "Origen",
          valueTitle: _ctx.origin,
          subTitle: "Partida",
          valueSubTitle: _ctx.hourInit
        }, null, 8, ["valueTitle", "valueSubTitle"])
      ]),
      _: 1
    }),
    _createVNode(_component_v_col, { cols: "6" }, {
      default: _withCtx(() => [
        _createVNode(_component_PaymentBoxRouteAirport, {
          title: "Destino",
          valueTitle: _ctx.destination,
          subTitle: "Llegada",
          valueSubTitle: _ctx.hourFinish
        }, null, 8, ["valueTitle", "valueSubTitle"])
      ]),
      _: 1
    })
  ], 64))
}