
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { usePlanningStore } from '@/store/modules/planning';
import { GetCouponNameResponse } from '@/types/response/coupon';
import { formatCurrency } from '@/utils';
import { Options, Vue } from 'vue-class-component';


@Options({
    name: "PaymentPriceAirport",
    props: {
        couponResponse: {
            type: Object,
            default: null,
            required: false
        },
        discount: {
            type: Number,
            required: false,
            default: 0
        }
    }
})
export default class PaymentPriceAirport extends Vue {
    public formatCurrency = formatCurrency;
    public airportBookingStore = useAirportBookingStore();
    public couponResponse!: GetCouponNameResponse;
    public discount !: number;

    get price() {
        if(this.airportBookingStore.vehicleAiportSelected){
            return this.airportBookingStore.vehicleAiportSelected.price
        }
        return 0
    }
}
