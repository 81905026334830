
import { Vue, Options } from "vue-class-component";
import LocationPayment from "@/assets/images/icons/location-payment.svg";
import { useRoutesStore } from "@/store/modules/route";
import { usePlanningStore } from "@/store/modules/planning";

@Options({
  name: "PaymentBoxRouteAirport",
  props: {
    title: {
      type: String,
      default: "",
    },
    valueTitle: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    valueSubTitle: {
      type: String,
      default: "",
    },
  },
})
export default class PaymentBoxRouteAirport extends Vue {
  public routeStore = useRoutesStore();
  public planningStore = usePlanningStore();
  public LocationPayment = LocationPayment;
  public title !:string;
  public valueTitle !:string;
  public subTitle !:string;
  public valueSubTitle !:string;
}
