
import { formatDateSimple, convertTo12HourFormatV2 } from '@/filters/formatDate';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { GetHourPlaceVehicle } from '@/types/response/locations';
import { CreateTripOwnerAirport } from '@/types/request/trip';
import { Options, Vue } from 'vue-class-component';
import imageCar from '@/assets/car.png'
import { formatCurrency, formatRutChile, repeatEmail, requiredField, ruleValidateRut, validateEmail, validateNumber } from '@/utils';
import { TripAirportApi } from '@/api/trip/aiport'
import { UserApi } from '@/api/users';
import { FormVuetify, SelectData } from '@/types/common/vuetify';
import { useAuthStore } from '@/store/modules/auth';
import HeaderCommonBooking from './HeaderCommonBooking.vue';
import ModalCommon from '../common/ModalCommon.vue';

@Options({
    name: 'DetailSectoDataUser',
    components: {
        HeaderCommonBooking,
        ModalCommon
    }
})
export default class DetailSectoDataUser extends Vue {
    public imageCar = imageCar;
    public apiAirport = new TripAirportApi()
    public airportStore = useAirportBookingStore();
    private authStore = useAuthStore();
    public requiredField = requiredField;
    public ruleValidateRut = ruleValidateRut;
    public formatDateSimple = formatDateSimple;
    public formatCurrency = formatCurrency;
    public validateEmail = validateEmail;
    public convertTo12HourFormatV2 = convertTo12HourFormatV2;
    repeatEmail = repeatEmail;
    public isVisibleModalFavorite = false;
    public repeatEmailValue = "";
    public hours: string[] = []
    private userApi = new UserApi();
    public loadingCreated = false;
    public passengerselected = "";
    public docSelected = "RUT"
    public vehiclesAvailable: GetHourPlaceVehicle[] = [];
    public ownerData: CreateTripOwnerAirport = {
        firstName: undefined,
        lastName: undefined,
        rut: undefined,
        phone: undefined,
        email: undefined
    }
    public loaderUserRUT = false;

    get isLogged() {
        return this.authStore.isLogged;
    }

    get favoritePassengers() {
        if (this.authStore.user) {
            const user = this.authStore.user;
            return user.passengersFavorite;
        }
        return [];
    }

    public docs: SelectData[] = [
        {
            title: 'RUT',
            value: 'RUT'
        },
        {
            title: 'Otro',
            value: 'Otro'
        }
    ]

    get userLogged() {
        return this.authStore.user;
    }

    get step() {
        return this.airportStore.step
    }

    get place() {
        return this.airportStore.placeInfo
    }

    get dataForm() {
        return this.airportStore.form
    }

    get vehicleAiportSelected() {
        return this.airportStore.vehicleAiportSelected
    }

    get hourSelected() {
        return this.airportStore.hourSelected
    }

    validateNumber = validateNumber;
    formatReverse() {
        if (!this.ownerData.rut) {
            return;
        }
        this.ownerData.rut = this.ownerData.rut.replace(/[^\dKk]/g, "");
    }

    async format() {
        if (!this.ownerData.rut) {
            return;
        }
        this.ownerData.rut = formatRutChile(this.ownerData.rut);
        this.loaderUserRUT = true;
        try {
            if (this.ownerData.rut.length > 3) {
                const user = await this.userApi.getCustomerByRUT(this.ownerData.rut);
                this.ownerData.firstName = user.firstName;
                this.ownerData.lastName = user.lastName;
                this.ownerData.email = user.email || "";
                this.ownerData.phone = user.phone || "";
                this.repeatEmailValue = user.email || "";
            }
        } catch (error) {
            console.log("Usuario no encontrado");
        }
        this.loaderUserRUT = false;
    }

    addRutPersonal() {
        this.docSelected = "RUT"
        if (this.userLogged?.rut) {
            this.ownerData.rut = this.userLogged.rut;
            this.$nextTick(() => {
                this.format();
            });
        }
    }

    toBack() {
        this.airportStore.backSelectedVehicle();
    }

    async toPay() {
        const form = this.$refs['formDataUser'] as FormVuetify
        if (!form) {
            return;
        }
        const valid = await form.validate()
        if (valid.valid) {
            this.airportStore.setDataUser(this.ownerData);
            this.airportStore.setStep('payment');
        }
    }

    setVisibleFavoritePassenger() {
        this.isVisibleModalFavorite = true;
    }
    closeModalFavorite() {
        this.isVisibleModalFavorite = false;
        this.passengerselected = "";
    }
    setPassenger() {
        this.docSelected = "RUT"
        const seatingFavorite = this.favoritePassengers.find((fp) => fp._id == this.passengerselected);
        if (seatingFavorite) {
            this.ownerData.firstName = seatingFavorite.firstName;
            this.ownerData.lastName = seatingFavorite.lastName;
            this.ownerData.email = seatingFavorite.email || "";
            this.ownerData.phone = seatingFavorite.phone || "";
            this.ownerData.rut = seatingFavorite.rut;
            this.repeatEmailValue = seatingFavorite.email || "";
        }
        this.closeModalFavorite();
    }
}
