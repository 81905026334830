
import { CouponApi } from '@/api/coupons';
import { useAirportBookingStore } from '@/store/modules/airportBooking';
import { useMainStore } from '@/store/modules/main';
import { GetCouponNameResponse } from '@/types/response/coupon';
import { getErrorCatch } from '@/utils';
import { Options, Vue } from 'vue-class-component';

@Options({
    name: "PaymentCouponAirport"
})
export default class PaymentCouponAirport extends Vue {
    public isCoupon = false;
    public airportBookingStore = useAirportBookingStore();
    public mainStore = useMainStore();
    public couponName = "";
    public loadingCoupon = false
    public couponResponse: GetCouponNameResponse | null = null;
    private couponApi = new CouponApi();
    public discount = 0;


    get total() {
        if (this.airportBookingStore.vehicleAiportSelected) {
            return this.airportBookingStore.vehicleAiportSelected.price;
        }
        return 0
    }

    async searchCoupon() {
        if (this.couponName === "") return;
        this.loadingCoupon = true;
        try {
            const coupon = await this.couponApi.validateCoupon(
                this.couponName,
                this.total
            );
            this.discount =
                coupon.type === "PERCENTAJE" || coupon.percentaje
                    ? this.calculatePercenjate(coupon)
                    : coupon.amount || 0;
            this.couponResponse = coupon;
            this.mainStore.setNotification({
                isOpen: true,
                message: "Cupón agregado correctamente",
                color: "green darken-2",
                buttonClose: true,
            });
            localStorage.setItem("coupon", JSON.stringify(coupon));
        } catch (error) {
            getErrorCatch(error);
            this.couponResponse = null;
            this.discount = 0;
        }
        this.$emit('searchCoupon', {
            couponResponse: this.couponResponse,
            discount: this.discount,
            name: this.couponName
        });
        this.loadingCoupon = false;
    }
    calculatePercenjate(coupon?: GetCouponNameResponse) {
        if (coupon) {
            if (
                (coupon.type === "PERCENTAJE" || coupon.percentaje) &&
                coupon.percentaje
            ) {
                const discount = this.total * (coupon.percentaje / 100);
                return isNaN(discount) ? 0 : discount;
            }
        }
        return 0;
    }

    updateCoupon(value: boolean) {
        if (!value) {
            this.couponName = '';
            this.$emit('searchCoupon', {
                couponResponse: null,
                discount: 0,
                name: ''
            })
        }
    }
}
