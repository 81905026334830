import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d flex-column w-100" }
const _hoisted_2 = { ref: "calendarContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_card, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_card_title, { class: "d-flex py-4" }, {
          default: _withCtx(() => [
            _createElementVNode("div", null, [
              _createVNode(_component_v_btn, {
                active: _ctx.viewCalendar == 'day',
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setViewer('day'))),
                variant: "text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Día")
                ]),
                _: 1
              }, 8, ["active"]),
              _createVNode(_component_v_btn, {
                active: _ctx.viewCalendar == 'week',
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setViewer('week'))),
                variant: "text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Semana")
                ]),
                _: 1
              }, 8, ["active"]),
              _createVNode(_component_v_btn, {
                active: _ctx.viewCalendar == 'month',
                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setViewer('month'))),
                variant: "text"
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Mes")
                ]),
                _: 1
              }, 8, ["active"])
            ]),
            _createVNode(_component_v_spacer),
            _createElementVNode("div", null, [
              _createVNode(_component_v_btn, {
                variant: "outlined",
                rounded: "10",
                class: "mr-2",
                onClick: _ctx.prev
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-arrow-left")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_v_btn, {
                variant: "outlined",
                rounded: "10",
                class: "ml-2",
                onClick: _ctx.next
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, null, {
                    default: _withCtx(() => [
                      _createTextVNode("mdi-arrow-right")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_v_card_text, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, null, 512)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}