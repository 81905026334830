
import { GetTripResponse } from "@/types/response/trip";
import { Options, Vue } from "vue-class-component";
import LocationPayment from "@/assets/images/icons/location-payment.svg";
import Line from "@/assets/images/icons/linea.svg";
import { convertTo12HourFormat, convertTo12HourFormatV2, getDate } from "@/filters/formatDate";
import { STATUS_ACTIVE_TRIP } from "@/types/response/planning";
import { formatCurrency } from "@/utils";
import InfoRoute from "./customer/InfoRoute.vue";
import InfoHourRoute from "./customer/InfoHourRoute.vue";

@Options({
  name: "ModalTripDetail",
  components: { InfoRoute, InfoHourRoute },
  props: {
    tripSelected: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
})
export default class ModalTripDetail extends Vue {
  public tripSelected!: GetTripResponse;
  public isVisible!: boolean;
  public LocationPayment = LocationPayment;
  public Line = Line;
  public convertTo12HourFormat = convertTo12HourFormat;
  public getDate = getDate;
  public formatCurrency = formatCurrency;
  public activeTrip: "outbound" | "return" = "outbound";

  get seatings() {
    if (!this.tripSelected) return "";
    const seatingOwner = this.tripSelected.seating;
    const seatingPassengers = this.tripSelected.passengers.map(
      ({ seating }) => seating
    );
    seatingPassengers.push(seatingOwner);
    seatingPassengers.sort(this.sortSeatings);
    return seatingPassengers.join(",");
  }

  get addressFormatOrigin(){
        if (!this.tripSelected || !this.tripSelected.dataLocationAirport) {
            return '';
        }
        if(this.tripSelected.stationOrigin.split(",").length>2){
            const {descAddress} = this.tripSelected.dataLocationAirport;
            return `${descAddress.route} ${descAddress.street_number}, ${descAddress.locality}`
        }
        return this.tripSelected.stationOrigin
    }

    get addressFormatDestination(){
        if (!this.tripSelected || !this.tripSelected.dataLocationAirport) {
            return '';
        }
        if(this.tripSelected.stationDestination.split(",").length>2){
            const {descAddress} = this.tripSelected.dataLocationAirport;
            return `${descAddress.route} ${descAddress.street_number}, ${descAddress.locality}`
        }
        return this.tripSelected.stationDestination
    }

  get fullName() {
    if (!this.tripSelected) return ''
    return `${this.tripSelected.owner.firstName} ${this.tripSelected.owner.lastName}`;
  }

  get isRound() {
    if (!this.tripSelected) return false;
    return this.tripSelected.typeTrip == 'round';
  }

  get isOutboud() {
    if (!this.tripSelected) return false;
    return this.activeTrip == 'outbound';
  }

  get originStation() {
    if (!this.tripSelected) return '';
    if (this.tripSelected.stationOrigin !== 'Aeropuerto' && this.tripSelected.dataLocationAirport && this.tripSelected.dataLocationAirport.descAddress) {
      const { descAddress } = this.tripSelected.dataLocationAirport;
      return `${descAddress.route} ${descAddress.street_number}, ${descAddress.locality}`
    }
    return this.tripSelected.stationOrigin;
  }

  get destinationStation() {
    if (!this.tripSelected) return '';
    if (this.tripSelected.stationDestination !== 'Aeropuerto' && this.tripSelected.dataLocationAirport && this.tripSelected.dataLocationAirport.descAddress) {
      const { descAddress } = this.tripSelected.dataLocationAirport;
      return `${descAddress.route} ${descAddress.street_number}, ${descAddress.locality}`
    }
    return this.tripSelected.stationDestination;
  }

  get hourDeparture() {
    if (!this.tripSelected) return "";
    if (this.tripSelected.dataLocationAirport) {
      return convertTo12HourFormatV2(this.tripSelected.hourDeparture);
    }
    return this.tripSelected.hourDeparture;
  }

  get hourArrival() {
    if (!this.tripSelected) return "";
    if (this.tripSelected.dataLocationAirport) {
      return convertTo12HourFormatV2(this.tripSelected.hourArrival);
    }
    return this.tripSelected.hourArrival;
  }

  get hourReturnDeparture() {
    if (!this.tripSelected) return "";
    if (this.tripSelected.dataLocationAirport && this.tripSelected.returnData) {
      return convertTo12HourFormatV2(this.tripSelected.returnData.hourDeparture);
    }
    return this.tripSelected.hourDeparture;
  }

  get hourReturnArrival() {
    if (!this.tripSelected) return "";
    if (this.tripSelected.dataLocationAirport && this.tripSelected.returnData) {
      return convertTo12HourFormatV2(this.tripSelected.returnData.hourArrival);
    }
    return this.tripSelected.hourArrival;
  }

  get seatingsReturn() {
    if (!this.tripSelected) return "";
    const seatingOwner = this.tripSelected.returnData?.seatingOwner || "";
    let finalSeating : string[] = [seatingOwner]
    const seatingPassengers: string[] = this.tripSelected.returnData
      ? this.tripSelected.returnData.passengers
      : [];
    finalSeating.concat(seatingPassengers);
    finalSeating.sort(this.sortSeatings);
    return finalSeating.join(",");
  }

  sortSeatings(a: string, b: string) {
    return a.localeCompare(b, undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }

  closeDialog() {
    this.$emit("closeModal");
  }
  getStatus(entryStatus: string) {
    console.log(entryStatus);
    const status: Record<string, string> = {
      ON_HOLD: "En espera",
      IN_PROGRESS: "En progreso",
      FINALIZED: "Finalizado",
      REMOVED: "Viaje eliminado por admin.",
    };
    return status[entryStatus] || "";
  }
  getColorIcon(status: STATUS_ACTIVE_TRIP) {
    let color = "black";
    switch (status) {
      case STATUS_ACTIVE_TRIP.ON_HOLD:
        color = "blue";
        break;
      case STATUS_ACTIVE_TRIP.IN_PROGRESS:
        color = "green";
        break;
      case STATUS_ACTIVE_TRIP.FINALIZED:
        color = "primary";
        break;
      case STATUS_ACTIVE_TRIP.REMOVED:
        color = "red";
        break;
    }
    return `${color} darken-2`;
  }
  setActiveTrip(active: "outbound" | "return" = "outbound") {
    this.activeTrip = active;
  }
}
