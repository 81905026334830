import API from "@/utils/request";
import { ROUTES_GLOBAL } from "../paths";
import { AxiosInstance } from "axios";
import { SCHEDULE_ENDPOINTS } from "./endpoint";
import { GetMyScheduleResponse,GetMyScheduleAdminResponse} from "@/types/response/schedule";
import { GetPlaceIdRes, VehicleListMapping } from "@/types/response/locations";

export class ScheduleApi {
  private request = new API(ROUTES_GLOBAL.SCHEDULE);
  private api: AxiosInstance = this.request.getInstance();

  async get(date: string){
    const {data} = await this.api.get<GetMyScheduleAdminResponse[]>(SCHEDULE_ENDPOINTS.GET_MY_SCHEDULE+ "?date=" + date);
    return data;
  }

  async getAdmin(date: string){
    const {data} = await this.api.get<GetMyScheduleAdminResponse[]>(SCHEDULE_ENDPOINTS.GET_SCHEDULE_ADMIN + "?date=" + date);
    return data;
  }

  async startTrip(id: string){
    const {data} = await this.api.put(SCHEDULE_ENDPOINTS.START_TRIP.replace(":id", id));
    return data;
  }

  async endTrip(id: string){
    const {data} = await this.api.put(SCHEDULE_ENDPOINTS.END_TRIP.replace(":id", id));
    return data;
  }

  async resetTrip(id: string){
    const {data} = await this.api.put(SCHEDULE_ENDPOINTS.RESET_TRIP.replace(":id", id));
    return data;
  }

  async listDriversAvailable(id: string){
    const {data} = await this.api.get<GetPlaceIdRes[]>(SCHEDULE_ENDPOINTS.LIST_DRIVERS_AVAILABLE.replace(":id", id));
    return data;
  }

  async updateDriverVehicleSchedule(id:string, driver: string, vehicle: string){
    const {data} = await this.api.put<VehicleListMapping>(SCHEDULE_ENDPOINTS.UPDATE_DRIVER_VEHICLE_SCHEDULE.replace(":id", id), {driver, vehicle});
    return data;
  }
}
