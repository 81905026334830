
import { UrlConstants } from "@/constants/url.constants";
import { useMainStore } from "@/store/modules/main";
import { GetTripResponse } from "@/types/response/trip";
import { getErrorCatch } from "@/utils";
import { Options, Vue } from "vue-class-component";
import { useShare } from "@vueuse/core";
import { STATUS_ACTIVE_TRIP,STATUS_SCHEDULE_DRIVE } from "@/types/response/planning";

@Options({
  name: "ButtonsTrip",
  props: {
    trip: {
      type: Object,
      default: () => ({}),
      required: false,
    },
  },
  emits: ["openDialog", "openCancelTrip", "openDialogRoute"],
})
export default class ButtonsTrip extends Vue {
  public trip!: GetTripResponse;
  private mainStore = useMainStore();
  public valueGPS = "";
  public public_url = UrlConstants.URL_PUBLIC_WEB;

  get isActiveShared() {
    const { isSupported } = useShare();
    return isSupported;
  }

  get activeOneRoute() {
    try {
      if (this.trip) {
        return this.trip.activeTrip.status !== STATUS_ACTIVE_TRIP.FINALIZED && this.trip.activeTrip.status !== STATUS_ACTIVE_TRIP.REMOVED;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  get validateRoute() {
    if (!this.trip) {
      return false;
    }
    const { typeCancelTrip, typeTrip, activeTrip, returnDataActiveTrip } = this.trip
    if (typeTrip == 'round') {
      if (!returnDataActiveTrip) {
        return false;
      }
      if (typeCancelTrip && typeCancelTrip.length > 0) {
        const isOneWay = typeCancelTrip.includes('ONE_WAY');
        const isOnlyReturn = typeCancelTrip.includes('ONLY_RETURN');
        if (isOneWay) {
          if (isOnlyReturn) return false;
          return activeTrip.status === STATUS_ACTIVE_TRIP.ON_HOLD;
        }

        if (isOnlyReturn) return true;
        return returnDataActiveTrip.status === STATUS_ACTIVE_TRIP.ON_HOLD;
      }
      const activeOnHold = activeTrip.status === STATUS_ACTIVE_TRIP.ON_HOLD;
      const returnOnHold = returnDataActiveTrip.status === STATUS_ACTIVE_TRIP.ON_HOLD;
      if (activeOnHold) return !returnOnHold;
      if (returnOnHold) return true;
      return false;
    } else {
      return true;
    }
  }

  get activeTwoRoute() {
    try {
      if (this.trip) {
        if (this.trip.typeTrip == "round") {
          if (
            this.trip.returnDataActiveTrip !== undefined &&
            this.trip.returnDataActiveTrip.status !== undefined
          ) {
            return this.trip.returnDataActiveTrip.status !== STATUS_ACTIVE_TRIP.FINALIZED && this.trip.returnDataActiveTrip.status !== STATUS_ACTIVE_TRIP.REMOVED;
          }
          return false;
        }
        return false;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  shared(type?: "IDA" | "VUELTA" | null) {
    let valueGPS = this.public_url + "donde-va-mi-van/";
    switch (type) {
      case "VUELTA":
        valueGPS += this.trip.returnDataActiveTrip?._id;
        break;
      case "IDA":
        valueGPS += this.trip.activeTrip._id;
        break;
      default:
        valueGPS += this.trip.activeTrip._id;
        break;
    }
    valueGPS += `?code=${this.trip._id}&tipo=${type !== null ? type : "ALL"}`;
    try {
      if (this.isActiveShared) {
        const { share } = useShare();
        share({ title: "Compartir mi ruta", text: valueGPS, url: valueGPS });
      }
    } catch (error) {
      getErrorCatch(error);
    }
  }

  validateTripGPS(type: "IDA" | "VUELTA" = "IDA") {
    const { activeTrip, returnDataActiveTrip } = this.trip;

    if (type == "IDA" && activeTrip) {
      return activeTrip.status == STATUS_ACTIVE_TRIP.IN_PROGRESS;
    }
    if (type == "VUELTA" && returnDataActiveTrip) {
      return returnDataActiveTrip.status == STATUS_ACTIVE_TRIP.IN_PROGRESS;
    }
  }

  copy(type?: "IDA" | "VUELTA" | null) {
    switch (type) {
      case "VUELTA":
        this.valueGPS =
          this.public_url +
          "donde-va-mi-van/" +
          this.trip.returnDataActiveTrip?._id;
        break;
      case "IDA":
        this.valueGPS =
          this.public_url + "donde-va-mi-van/" + this.trip.activeTrip._id;
        break;
      default:
        this.valueGPS =
          this.public_url + "donde-va-mi-van/" + this.trip.activeTrip._id;
        break;
    }
    this.valueGPS =
      this.valueGPS +
      `?code=${this.trip._id}&tipo=${type !== null ? type : "ALL"}`;
    this.$nextTick(() => {
      const copyText: any = document.getElementById("inputGPS");
      if (!copyText) return;
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value);
      this.mainStore.setNotification({
        isOpen: true,
        color: "green-darken-2",
        message: "Link copiado",
      });
    });
  }

  toGPS(type?: "IDA" | "VUELTA" | null) {
    let urlFinal = "/donde-va-mi-van/";
    switch (type) {
      case "VUELTA":
        urlFinal += this.trip.returnDataActiveTrip?._id;
        break;
      case "IDA":
        urlFinal += this.trip.activeTrip._id;
        break;
      default:
        urlFinal += this.trip.activeTrip._id;
        break;
    }
    urlFinal += `?code=${this.trip._id}&tipo=${type !== null ? type : "ALL"}`;
    this.$router.push(urlFinal);
  }

  openDialog() {
    this.$emit("openDialog", this.trip);
  }

  openDialogRoute() {
    this.$emit('openDialogRoute', this.trip)
  }

  openCancelTrip() {
    this.$emit("openCancelTrip", this.trip);
  }


  validateTripAirport() {
    const { status, schedule,typeTrip, adminPurchase, typeCancelTrip,isLateReturn,isLate } = this.trip;
    if (status == "ACTIVE") {
      // validando por tipo de viaje
      if (typeTrip == "justReturn") {
        if (
          typeCancelTrip &&
          typeCancelTrip.length > 0 &&
          typeCancelTrip.includes("ONE_WAY")
        ) {
          // valida si el viaje está cancelado, esto deberia retornar true ya que no se peude cancelar doble
          return true;
        } else {
          // no existe cancelamientos se retorna la validacion de tardanza
          return isLate;
        }
      } else if (typeTrip == "round") {
        if (typeCancelTrip && typeCancelTrip.length > 0) {
          // existe cancelamientos
          if (typeCancelTrip.includes("BOTH")) {
            // se han cancelado las dos rutas
            return true;
          } else if (typeCancelTrip.includes("ONE_WAY")) {
            // se ha cancelado el de ida, retornamos tardanza de ruta de regreso
            return isLateReturn;
          } else if (typeCancelTrip.includes("ONLY_RETURN")) {
            return isLate;
          }
          // ningun caso
          return true;
        } else {
          // validacion para ver si alguna ruta es tarde, si por lo menos una no es tarde dejara seguir
          const isLateFinal = isLate && isLateReturn;
          if (!isLateFinal) {
            const isFinalTripIda = schedule.status !== STATUS_SCHEDULE_DRIVE.ON_HOLD
            const isFinalTripVuelta = false
            return isFinalTripIda && isFinalTripVuelta
          }
          return isLateFinal
        }
      } else {
        // no deberia llegar aqui
        return true;
      }
    } else {
      if (adminPurchase) {
        return false;
      }
      if (status == "CANCEL" && typeCancelTrip) {
        // existe un cancelamiento
        if (
          (typeCancelTrip.includes("ONE_WAY") &&
            typeCancelTrip.includes("ONLY_RETURN")) ||
          typeCancelTrip.includes("BOTH")
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  }

  validateTrip() {
    const {
      isLate,
      isLateReturn,
      typeCancelTrip,
      typeTrip,
      status,
      adminPurchase,
      activeTrip,
      returnDataActiveTrip
    } = this.trip;
    if (status == "ACTIVE") {
      // validando por tipo de viaje
      if (typeTrip == "justReturn") {
        if (
          typeCancelTrip &&
          typeCancelTrip.length > 0 &&
          typeCancelTrip.includes("ONE_WAY")
        ) {
          // valida si el viaje está cancelado, esto deberia retornar true ya que no se peude cancelar doble
          return true;
        } else {
          // no existe cancelamientos se retorna la validacion de tardanza
          return isLate;
        }
      } else if (typeTrip == "round") {
        if (typeCancelTrip && typeCancelTrip.length > 0) {
          // existe cancelamientos
          if (typeCancelTrip.includes("BOTH")) {
            // se han cancelado las dos rutas
            return true;
          } else if (typeCancelTrip.includes("ONE_WAY")) {
            // se ha cancelado el de ida, retornamos tardanza de ruta de regreso
            return isLateReturn;
          } else if (typeCancelTrip.includes("ONLY_RETURN")) {
            return isLate;
          }
          // ningun caso
          return true;
        } else {
          // validacion para ver si alguna ruta es tarde, si por lo menos una no es tarde dejara seguir
          const isLateFinal = isLate && isLateReturn;
          if (!isLateFinal) {
            const isFinalTripIda = activeTrip.status !== STATUS_ACTIVE_TRIP.ON_HOLD
            const isFinalTripVuelta = returnDataActiveTrip?.status !== STATUS_ACTIVE_TRIP.ON_HOLD
            return isFinalTripIda && isFinalTripVuelta
          }
          return isLateFinal
        }
      } else {
        // no deberia llegar aqui
        return true;
      }
    } else {
      if (adminPurchase) {
        return false;
      }
      if (status == "CANCEL" && typeCancelTrip) {
        // existe un cancelamiento
        if (
          (typeCancelTrip.includes("ONE_WAY") &&
            typeCancelTrip.includes("ONLY_RETURN")) ||
          typeCancelTrip.includes("BOTH")
        ) {
          return true;
        } else {
          return false;
        }
      }
      return true;
    }
  }
}
