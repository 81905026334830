
import { getDate } from '@/filters/formatDate';
import { Options, Vue } from 'vue-class-component';
import { useAirportBookingStore } from '@/store/modules/airportBooking';


@Options({
    name: "PaymentScheduleAirport",
    props: {
        isReturn: {
            type: Boolean,
            required: false,
            default: false
        }
    }
})
export default class PaymentScheduleAirport extends Vue {
    public airportBookingStore = useAirportBookingStore();
    public getDate = getDate;
    public isReturn !: boolean;

    get dateExecuting() {
        if(this.airportBookingStore.form.date){
            return getDate(
                this.airportBookingStore.form.date.getTime(),
            "onlyDate",
            "NUMBER",
            false
        )
        }
        return ''
    }

    get durationTrip() {
        if(this.airportBookingStore.vehicleAiportSelected){
            return this.airportBookingStore.vehicleAiportSelected.duration
        }
        return ''
    }
}
